import { render, staticRenderFns } from "./NewEdit.vue?vue&type=template&id=12414583&scoped=true&"
import script from "../../models/app-notification/new-edit.ts?vue&type=script&lang=ts&"
export * from "../../models/app-notification/new-edit.ts?vue&type=script&lang=ts&"
import style1 from "./NewEdit.vue?vue&type=style&index=1&id=12414583&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12414583",
  null
  
)

export default component.exports