import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { DetailRequest, RegisterRequest } from "@/api/schedule/request";
import ScheduleShopGet from "@/store/schedule-shop/get";
import ScheduleDetail from "@/store/schedule/detail";
import ScheduleRegister from "@/store/schedule/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import _ from "lodash";
import WindowSizeChecker from "@/utils/window-size-checker";
import { UlFileRegisiterTabType } from "@/utils/enums";
import WindowOpen from "@/utils/window-open";
import { GetRequest } from "@/api/shop-agreement/request";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import Admin from "@/store/admin/admin";
import service from "@/api/service";
import UiFormSocial from "@/components/UIFormSocial.vue";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDatePicker,
    UIFormRow,
    UlFileRegister,
    UiFormSocial
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "アプリ内通知";
  headingSub = "App notification";

  initImageUrl = null as string | null;
  // <!-- 20201203 NEW_DEV-734 C37-No.41 cyber 王 start -->
  allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
  // <!-- 20201203 NEW_DEV-734 C37-No.41 cyber 王 end -->
  tabType = UlFileRegisiterTabType.Photo;

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  // PC推奨サイズ
  imageSizePc = {
    width: 840,
    height: 1260
  }

  // アプリ推奨サイズ
  imageSizeApp = {
    width: 420,
    height: 630
  }

  imageFileSizePc = 500;
  imageFileSizeApp = 500;

  pixelSizeDescription = null as string | null;
  fileSizeDescription = null as string | null;

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  isDialogShowing = false;

  inputParams = {
    /** お知らせ・イベントID */
    id: null as number | null,
    /** 店舗ID */
    shopId: 0,
    /** 公開開始日 */
    viewDate: "",
    /** 公開終了日 */
    viewDateEnd: "",
    /** 20201203 NEW_DEV-734 C37-No.08 cyber 肖 start */
    /** 区分 */
    kbn: "",
    /** 20201203 NEW_DEV-734 C37-No.08 cyber 肖 end */
    /** TOPのみ表示フラグ */
    topDisplay: 0,
    /** 外部リンクURL */
    url: null,
    /** タイトル */
    title: "",
    /** 本文 */
    comment: "",
    /** 画像編集フラグ */
    imageEditFlg: 0,
    /** 画像パス */
    imagePath: null as string | null,
    /** t_shop_image_histories.id */
    imageId: null,
    /** 公開設定 */
    viewFlg: 0,
    /** プレビュー */
    isPreview: null as number | null,

    /* new form social*/
    snsPostLinkFlg: 0 as 0 | 1,
    snsInfoLinkFlg: 0 as 0 | 1,

    twitterPostFlgExt: 0 as 0 | 1,
    facebookPostFlgExt: 0 as 0 | 1,
    instagramPostFlgExt: 0 as 0 | 1,
    googleBizProfPostFlgExt: 0 as 0 | 1,
    twitterFlgExt: 0 as 0 | 1,
    facebookFlgExt: 0 as 0 | 1,
    instagramFlgExt: 0 as 0 | 1,
    googleBizProfFlgExt: 0 as 0 | 1,

    twitterFlag: 0 as 0 | 1,
    facebookFlag: 0 as 0 | 1,
    instagramFlag: 0 as 0 | 1,
    googleBizFlag: 0 as 0 | 1,
    twitterMessage: '',
    facebookMessage: '',
    instagramMessage: '',
    googleBizMessage: ''
  } as RegisterRequest;

  isSuccess = 0;

  get isEdit() {
    return !!this.id;
  }

  get breadCrumbs() {
    if (this.isEdit) {
      return [
        { text: "アピール", disabled: true },
        { text: "アプリ内通知", disabled: true },
        {
          text: "店舗一覧",
          disabled: false,
          to: { name: "app-notification" }
        },
        {
          text: "アプリ内通知一覧",
          disabled: false,
          to: {
            name: "app-notification-list",
            params: { id: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "編集", disabled: true }
      ];
    } else {
      return [
        {
          text: "店舗一覧",
          disabled: false,
          to: { name: "app-notification" }
        },
        {
          text: "アプリ内通知一覧",
          disabled: false,
          to: {
            name: "app-notification-list",
            params: { id: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "新規作成", disabled: true }
      ];
    }
  }

  get shopId() {
    return this.$route.query.shopId ? this.$route.query.shopId : "";
  }

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  get isPhone() {
    return WindowSizeChecker.isPhone(this);
  }

  get isPhoneAndTablet() {
    return WindowSizeChecker.isPhoneAndTablet(this);
  }

  /**
   * 契約が未登録かの判定
   * @return true:契約が 未登録 false:契約が 登録済
   */
  isNotAgreement(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === false && this.agreements.web === false;
    return result;
  }

  fileDescription() {
    if (this.agreements.web === true) {
      this.pixelSizeDescription = "※推奨サイズ：" + this.imageSizePc.width + "px 縦幅" + this.imageSizePc.height + "px以下（推奨サイズの画像を登録いただくと綺麗に表示されます。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizePc + "KB以下";
    } else {
      this.pixelSizeDescription = "※推奨サイズ：" + this.imageSizeApp.width + "px 縦幅" + this.imageSizeApp.height + "px以下（推奨サイズの画像を登録いただくと綺麗に表示されます。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizeApp + "KB以下";
    }
  }

  iNitSocial() {
    if(this.inputParams.facebookMessage == '')
    {
      this.inputParams.facebookMessage = this.inputParams.comment;
    }
    if(this.inputParams.twitterMessage == '')
    {
      this.inputParams.twitterMessage = this.inputParams.comment;
    }
    if(this.inputParams.instagramMessage == '')
    {
      this.inputParams.instagramMessage = this.inputParams.comment;
    }
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (!this.shopId) {

      await this.$router.push({ name: "app-notification" });
      await Flash.setErrorNow({
        message: "パラメータが不足しています。",
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    this.inputParams.shopId = Number(this.shopId);
    var resExt = await service.post("/get-extension-social-shop", {shop_id : this.shopId});
    if (resExt.data) {
      this.inputParams.snsPostLinkFlg = resExt.data.snsPostLinkFlg;
      this.inputParams.snsInfoLinkFlg = resExt.data.snsInfoLinkFlg;

      this.inputParams.twitterPostFlgExt = resExt.data.twitterPostFlg;
      this.inputParams.facebookPostFlgExt = resExt.data.facebookPostFlg;
      this.inputParams.instagramPostFlgExt = resExt.data.instagramPostFlg;
      this.inputParams.googleBizProfPostFlgExt = resExt.data.googleBizProfPostFlg;
      this.inputParams.twitterFlgExt = resExt.data.twitterFlg;
      this.inputParams.facebookFlgExt = resExt.data.facebookFlg;
      this.inputParams.instagramFlgExt = resExt.data.instagramFlg;
      this.inputParams.googleBizProfFlgExt = resExt.data.googleBizProfFlg;
    }
    // 契約情報を取得する
    if (this.shopId) {
      let requestAgrement = {
        shopId: String(this.shopId)
      } as GetRequest;
      await ShopAgreementAPI.get(requestAgrement);
      if (!ShopAgreementAPI.isSuccess) {
        await Flash.setErrorNow({
          message: "",
          showReloadButton: true
        } as ErrorAlert);
        return;
      } else {
        const AgreementItems = ShopAgreementAPI.getagreementsList;
        AgreementItems.forEach((item: { agreementsType: number }) => {
          if (item.agreementsType == 1) {
            this.agreements.ap = true;
          } else if (item.agreementsType == 2) {
            this.agreements.line = true;
          } else if (item.agreementsType == 3) {
            this.agreements.web = true;
          }
        });
      }
    }
    if (this.isEdit) {
      await this.detail();
      this.syncInputParams();
    }
    this.fileDescription();

    if(this.inputParams.facebookPostFlgExt != 1 || this.inputParams.facebookFlgExt != 1) {
      this.inputParams.facebookFlag = 0;
    }
    if(this.inputParams.twitterPostFlgExt != 1 || this.inputParams.twitterFlgExt != 1) {
      this.inputParams.twitterFlag = 0;
    }
    if(this.inputParams.instagramPostFlgExt != 1 || this.inputParams.instagramFlgExt != 1) {
      this.inputParams.instagramFlag = 0;
    }
    if(this.inputParams.googleBizProfPostFlgExt != 1 || this.inputParams.googleBizProfFlgExt != 1) {
      this.inputParams.googleBizFlag = 0;
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ScheduleDetail.clearResponse();
    await ScheduleRegister.clear();
  }

  changeDateFromCallback(date: string) {
    this.inputParams.viewDate = date;
  }

  changeDateToCallback(date: string) {
    this.inputParams.viewDateEnd = date;
  }

  async redirectToPushNotificationNewOnDialog() {
    await this.$router.push({
      name: "push-notifications-new",
      query: { shopId: this.shopId, shopName: this.shopName }
    });
  }

  async redirectToAppNotificationNewOnDialog() {
    if (this.$route.name === "app-notification-edit") {
      await this.$router.replace({
        name: "app-notification-new",
        query: { shopId: this.shopId, shopName: this.shopName }
      });
    }
    this.$router.go(0);
  }

  setFile(data: string, id: number | null) {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.imagePath = data;
    this.inputParams.imageId = id;
  }

  unsetFile() {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.imagePath = null;
    this.inputParams.imageId = null;
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    if (ScheduleShopGet.isSuccess) {
      return true;
    }

    await ScheduleShopGet.get();
    if (!ScheduleShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ScheduleShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return ScheduleShopGet.isSuccess;
  }

  async detail() {
    this.isLoading = true;
    let isSuccess = await this.fetchShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    await ScheduleDetail.detail(this.createDetailRequest());
    if (!ScheduleDetail.isSuccess) {
      await Flash.setErrorNow({
        message: ScheduleDetail.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  async preview() {
    this.inputParams.isPreview = 1;
    await this.callRegisterApi();
    if (ScheduleRegister.isSuccess) {
      WindowOpen.preview(ScheduleRegister.getPreviewUrl);
    }
  }

  async webPreview() {
    this.inputParams.isPreview = 2;
    await this.callRegisterApi();
    if (ScheduleRegister.isSuccess) {
      WindowOpen.webPreview(ScheduleRegister.getPreviewUrl);
    }
  }

  async register() {
    this.inputParams.isPreview = 0;
    await this.callRegisterApi();
    if (ScheduleRegister.isSuccess) {
      if (this.agreements.ap === true || this.isNotAgreement() === true) {
        this.isDialogShowing = true;
      } else {
        await this.redirectWithSuccessAlert(
            "アプリ内通知情報を保存しました。",
            `/app-notification/${this.shopId}/list`,
            {shopName : this.shopName}
        );
      }
    } else {
      var res = ScheduleRegister.registerResponse;
        if(res.errors) {
          console.log(JSON.stringify(res.errors));
          await Flash.clear();
          await Flash.setErrorNow({
            message: ScheduleRegister.getMessage,
            showReloadButton: false,
            messageSuccessBasic: res.messageSuccessBasic ? res.messageSuccessBasic : ''
          } as ErrorAlert);
        }
    }
  }

  private async callRegisterApi() {
    await Flash.clear();
    this.isLoading = true;
    await ScheduleRegister.register(this.createRegisterRequest());
    if (!ScheduleRegister.isSuccess) {
      await Flash.setErrorNow({
        message: ScheduleRegister.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  private syncInputParams() {
    this.inputParams = {
      ...this.inputParams,
      ...ScheduleDetail.getDetail
    };
    this.inputParams.kbn = String(this.inputParams.kbn);
    this.initImageUrl = this.inputParams.imagePath;
  }

  private createDetailRequest() {
    return { id: this.id } as DetailRequest;
  }

  private createRegisterRequest() {
    const request = _.cloneDeep(this.inputParams);
    if (!this.inputParams.id) {
      delete request.id;
    }
    if (request.imageEditFlg == 0 || request.imageId != null) {
      delete request.imagePath;
    }
    /** オウンドアプリの契約がない場合（「お知らせ/イベント」非表示時）「お知らせ」固定 */
    if (this.agreements.ap === false && this.isNotAgreement() === false) {
      request.kbn = "2";
    }
    return request;
  }

}
