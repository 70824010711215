







































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { SnsMsgRequest } from '@/api/schedule/request';
import Config from "@/store/config/get";
@Component
export default class UIFormSocial extends Vue {
    @Prop({
        default: {
            twitterPostFlgExt: 0,   /* m_shop_extension.twitter_post_flg */
            facebookPostFlgExt: 0, /* m_shop_extension.facebook_post_flg */
            instagramPostFlgExt: 0, /* m_shop_extension.instagram_post_flg */
            googleBizProfPostFlgExt: 0, /* m_shop_extension.google_biz_post_flg */
            twitterFlgExt: 0,   /* m_shop_extension.twitter_flg */
            facebookFlgExt: 0, /* m_shop_extension.facebook_flg */
            instagramFlgExt:0,  /* m_shop_extension.instagram_flg */
            googleBizProfFlgExt: 0, /* m_shop_extension.google_bizz_flg */
            twitterFlag: 0,
            facebookFlag: 0,
            instagramFlag: 0,
            googleBizFlag: 0,
            twitterMessage: '',
            facebookMessage: '',
            instagramMessage: '',
            googleBizMessage: '',
        }
    })
    inputParams!: SnsMsgRequest;

    @Prop() activeSubTab?: number;
    @Prop() defaultMessage? : string;
    async mounted() {
        if(this.activeSubTab == 1) {
            if(this.inputParams.twitterMessage == '') {
                this.inputParams.twitterMessage = this.defaultMessage;
            }
            if(this.inputParams.facebookMessage == '') {
                this.inputParams.facebookMessage = this.defaultMessage;
            }
            if(this.inputParams.instagramMessage == '') {
                this.inputParams.instagramMessage = this.defaultMessage;
            }
            if(this.inputParams.googleBizMessage == '') {
                this.inputParams.googleBizMessage = this.defaultMessage;
            }
        }
        if(this.activeSubTab == 2) {
            if(this.inputParams.twitterMessage == this.defaultMessage)
            {
                this.inputParams.twitterMessage = '';
            }
            if(this.inputParams.facebookMessage == this.defaultMessage)
            {
                this.inputParams.facebookMessage = '';
            }
            if(this.inputParams.instagramMessage == this.defaultMessage)
            {
                this.inputParams.instagramMessage = '';
            }
            if(this.inputParams.googleBizMessage == this.defaultMessage)
            {
                this.inputParams.googleBizMessage = '';
            }
        }
    }

  imgHelp = 'help.png';
  imgExpTwitter = 'post_sample_twitter.png';
  imgExpFacebook = 'post_sample_facebook.png';
  imgExpInstagram = 'post_sample_instagram.png';
  imgExpGoogleBiz = 'post_sample_google_biz.png';

  get getConfig() {
    return Config.getItems;
  }

  async created() {
    await Config.get();
    if (!this.$route.params.id) {
      if (this.inputParams.twitterPostFlgExt && this.inputParams.twitterFlgExt) {
      this.inputParams.twitterFlag = 1;
      }
      if (this.inputParams.facebookPostFlgExt && this.inputParams.facebookFlgExt) {
        this.inputParams.facebookFlag = 1;
      }
      if (this.inputParams.instagramPostFlgExt && this.inputParams.instagramFlgExt) {
        this.inputParams.instagramFlag = 1;
      }
      if (this.inputParams.googleBizProfPostFlgExt && this.inputParams.googleBizProfFlgExt) {
        this.inputParams.googleBizFlag = 1;
      }
    }
  }

}
