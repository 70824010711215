import service from "@/api/service";
import { GetResponse } from "@/api/shop-agreement/response";
import { GetRequest } from "@/api/shop-agreement/request";

/**
 * 店舗契約情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-shop-agreement", getRequest);
  return response.data as GetResponse;
}
