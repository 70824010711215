




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UIFormRow extends Vue {
  @Prop({ default: true })
  border?: boolean;
}
